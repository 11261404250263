import React from "react"
import { Link } from "gatsby"

import Carousel from "./Carousel"

export const Hero2 = () => {
  return (
    <div className="relative mt-6 overflow-hidden bg-white">
      <div className="absolute inset-0 z-10 bg-gray-900 bg-opacity-50"></div>

      <div className="max-w-screen-xl mx-auto">
        <div className="relative z-10 pb-8 mx-auto sm:pb-16 md:pb-20 lg:w-full lg:pb-28 xl:pb-32">
          <div className="max-w-screen-xl px-4 pt-10 mx-auto sm:pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8 xl:pt-28">
            <div className="text-center">
              <h2 className="text-4xl font-extrabold leading-10 tracking-tight text-white sm:text-5xl sm:leading-none md:text-6xl">
                Insurance claim? <br className="hidden sm:block" />
                We will fight{" "}
                <span className="text-secondary-600">for you</span>
              </h2>
              <p className="mt-3 text-base text-center text-gray-100 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl">
                You don’t have to accept the insurance company’s denial or
                underpayment of your claim.
                <br />
                Let’s take the next step together and help you win your case.
              </p>
              <div className="justify-center mt-16 sm:mt-8 sm:flex">
                <div className="rounded-md shadow">
                  <Link
                    to="/contact"
                    className="flex items-center justify-center w-full px-8 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out border border-transparent rounded-md bg-secondary-600 hover:bg-secondary-500 focus:outline-none focus:shadow-outline md:py-4 md:text-lg md:px-10"
                  >
                    Contact Us Today
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <Carousel />
      </div>
    </div>
  )
}
