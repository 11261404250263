import React from "react"
import { Link } from "gatsby"

export const WhyChoose = () => {
  return (
    <div className="bg-gray-50">
      <div className="max-w-screen-xl px-4 py-12 mx-auto sm:text-center sm:px-6 lg:py-24 lg:px-8">
        <h2 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          Why Choose Rudin Legal?
          <br />
        </h2>
        <p className="max-w-5xl mt-4 text-xl leading-7 text-gray-500 lg:mx-auto">
          Focused on providing effective and professional legal counsel, Rudin
          Legal uses a hands-on, client-first approach. The law should serve the
          best interests of the people, and we advocate tirelessly to achieve
          our clients’ individual goals. Our team is committed to finding an
          ideal solution that results in the best possible outcome, regardless
          of the complexity of your legal needs.
        </p>
        <div className="flex justify-center mt-8">
          <div className="inline-flex w-full rounded-md shadow sm:w-auto">
            <Link
              to="/about"
              className="inline-flex items-center justify-center w-full px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out border border-transparent rounded-md bg-secondary-600 hover:bg-secondary-500 focus:outline-none focus:shadow-outline"
            >
              Learn more
            </Link>
          </div>
          {/* <div className="inline-flex ml-3">
            <Link
              to="/practices"
              className="inline-flex items-center justify-center px-5 py-3 text-base font-medium leading-6 transition duration-150 ease-in-out border border-transparent rounded-md text-secondary-700 bg-secondary-100 hover:text-secondary-600 hover:bg-secondary-50 focus:outline-none focus:shadow-outline focus:border-secondary-300"
            >
              How can we help
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  )
}
