import React from "react"
import { Link } from "gatsby"

export const Practices = () => {
  return (
    <div className="py-12 bg-white sm:py-24">
      <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="text-base font-semibold leading-6 tracking-wide uppercase text-secondary-600">
            Practices
          </p>
          <h3 className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Explore What Rudin Legal Can Do For You
          </h3>
          <p className="max-w-4xl mt-4 text-xl leading-7 text-gray-500 lg:mx-auto">
            We proudly offer a very specific set of litigation services tailored
            to the needs of Florida’s insureds. We advise clients in need of
            legal counsel in the following areas:
          </p>
        </div>

        <div className="py-12 bg-white">
          <div className="max-w-xl px-4 mx-auto sm:px-6 lg:max-w-screen-xl lg:px-8">
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <div className="flex flex-col sm:items-center">
                <div className="flex items-center justify-center w-12 h-12 text-white rounded-md bg-secondary-600">
                  <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M18 9.87V20H2V9.87a4.25 4.25 0 0 0 3-.38V14h10V9.5a4.26 4.26 0 0 0 3 .37zM3 0h4l-.67 6.03A3.43 3.43 0 0 1 3 9C1.34 9 .42 7.73.95 6.15L3 0zm5 0h4l.7 6.3c.17 1.5-.91 2.7-2.42 2.7h-.56A2.38 2.38 0 0 1 7.3 6.3L8 0zm5 0h4l2.05 6.15C19.58 7.73 18.65 9 17 9a3.42 3.42 0 0 1-3.33-2.97L13 0z" />
                  </svg>
                </div>
                <div className="mt-5">
                  <h5 className="text-lg font-medium leading-6 text-gray-900 sm:text-center">
                    <Link to="/practices/#property-insurance-claims">
                      Property Insurance Claims
                    </Link>
                  </h5>
                  <p className="mt-2 text-base leading-6 text-justify text-gray-500">
                    Thinking about making a property insurance claim? Has your
                    claim already been denied or underpaid?
                  </p>
                  <Link
                    to="/practices/#property-insurance-claims"
                    className="inline-block font-medium transition duration-150 ease-in-out text-secondary-500 hover:text-secondary-900 focus:outline-none focus:text-gray-900"
                  >
                    Learn more
                  </Link>
                </div>
              </div>

              <div className="flex flex-col mt-10 lg:mt-0 sm:items-center">
                <div className="flex items-center justify-center w-12 h-12 text-white rounded-md bg-secondary-600">
                  <svg
                    className="w-6 h-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                    />
                  </svg>
                </div>
                <div className="mt-5">
                  <h5 className="text-lg font-medium leading-6 text-gray-900 sm:text-center">
                    <Link to="/practices/#bad-faith-litigation">
                      Bad Faith Litigation
                    </Link>
                  </h5>
                  <p className="mt-2 text-base leading-6 text-justify text-gray-500">
                    Did your insurance company fail to conduct a reasonable,
                    thorough, and timely claim investigation?
                  </p>
                  <Link
                    to="/practices/#bad-faith-litigation"
                    className="inline-block mt-1 font-medium transition duration-150 ease-in-out text-secondary-500 hover:text-secondary-900 focus:outline-none focus:text-gray-900"
                  >
                    Learn more
                  </Link>
                </div>
              </div>
              <div className="flex flex-col mt-10 lg:mt-0 sm:items-center">
                <div className="flex items-center justify-center w-12 h-12 text-white rounded-md bg-secondary-600">
                  <svg
                    fill="currentColor"
                    className="w-6 h-6"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                      clipRule="evenodd"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <div className="mt-5">
                  <h5 className="text-lg font-medium leading-6 text-gray-900 sm:text-center">
                    <Link to="/practices/#condominium-association-disputes">
                      Condominium Association Disputes
                    </Link>
                  </h5>
                  <p className="mt-2 text-base leading-6 text-justify text-gray-500">
                    Has your condominium association’s failure to maintain
                    common elements resulted in damage to your property?
                  </p>
                  <Link
                    to="/practices/#condominium-association-disputes"
                    className="inline-block mt-1 font-medium transition duration-150 ease-in-out text-secondary-500 hover:text-secondary-900 focus:outline-none focus:text-gray-900"
                  >
                    Learn more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="mt-10">
          <ul className="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
            <li>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 text-white rounded-md bg-secondary-600">
                    <svg
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M18 9.87V20H2V9.87a4.25 4.25 0 0 0 3-.38V14h10V9.5a4.26 4.26 0 0 0 3 .37zM3 0h4l-.67 6.03A3.43 3.43 0 0 1 3 9C1.34 9 .42 7.73.95 6.15L3 0zm5 0h4l.7 6.3c.17 1.5-.91 2.7-2.42 2.7h-.56A2.38 2.38 0 0 1 7.3 6.3L8 0zm5 0h4l2.05 6.15C19.58 7.73 18.65 9 17 9a3.42 3.42 0 0 1-3.33-2.97L13 0z" />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg font-medium leading-6 text-gray-900">
                    <Link to="/practices/#property-insurance-claims">
                      Property Insurance Claims
                    </Link>
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Whether you are seeking to initiate an insurance claim, or
                    have already been denied or underpaid, let us fight for you.
                  </p>
                  <Link
                    to="/practices/#property-insurance-claims"
                    className="inline-block mt-1 font-medium transition duration-150 ease-in-out text-secondary-500 hover:text-secondary-900 focus:outline-none focus:text-gray-900"
                  >
                    Learn more
                  </Link>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 text-white rounded-md bg-secondary-600">
                    <svg
                      fill="currentColor"
                      className="w-6 h-6"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                        clipRule="evenodd"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg font-medium leading-6 text-gray-900">
                    <Link to="/practices/#condominium-association-disputes">
                      Condominium Association Disputes
                    </Link>
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    If your Condominium Association’s failure to maintain common
                    elements has caused you to suffer damage to your home, we
                    are here to help.
                  </p>
                  <Link
                    to="/practices/#condominium-association-disputes"
                    className="inline-block mt-1 font-medium transition duration-150 ease-in-out text-secondary-500 hover:text-secondary-900 focus:outline-none focus:text-gray-900"
                  >
                    Learn more
                  </Link>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 text-white rounded-md bg-secondary-600">
                    <svg
                      className="w-6 h-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h5 className="text-lg font-medium leading-6 text-gray-900">
                    <Link to="/practices/#bad-faith-litigation">
                      Bad Faith Litigation
                    </Link>
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    When an insurance company fails to handle a claim in good
                    faith, a policy holder may be entitled to monetary
                    compensation in addition to the cost of damage repair.
                  </p>
                  <Link
                    to="/practices/#bad-faith-litigation"
                    className="inline-block mt-1 font-medium transition duration-150 ease-in-out text-secondary-500 hover:text-secondary-900 focus:outline-none focus:text-gray-900"
                  >
                    Learn more
                  </Link>
                </div>
              </div>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  )
}
