import React from "react"

import { Layout, SEO } from "../components/Layout"
import { Hero2, Practices, WhyChoose } from "../components/Home"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <Hero2 />
    <WhyChoose />
    <Practices />
  </Layout>
)

export default IndexPage
