import React from "react"
import { wrap } from "@popmotion/popcorn"
import { Image, Transition } from "../helpers"

const images = [
  "shutterstock_130759928.jpg",
  "shutterstock_1176255829.jpg",
  "shutterstock_17466898.jpg",
  "shutterstock_720672.jpg",
  "shutterstock_119471224.jpg",
  "shutterstock_293057072.jpg",
  "shutterstock_89597407.jpg",
  "shutterstock_1033360891.jpg",
  "shutterstock_710118406.jpg",
  "shutterstock_432350506.jpg",
]

const Carousel = () => {
  const [page, setPage] = React.useState(0)

  React.useEffect(() => {
    const timer = setInterval(() => {
      setPage(prevCount => wrap(0, images.length, prevCount + 1))
    }, 5000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <>
      {images.map((image, i) => (
        <Transition
          key={image}
          show={page === i}
          enter="duration-400 ease-out"
          enterFrom="opacity-50"
          enterTo="opacity-100"
          leave="duration-300 ease-in"
          leaveFrom="opacity-200"
          leaveTo="opacity-50"
        >
          <div className="absolute inset-0">
            <Image
              className="object-cover object-top w-full h-56 h-full"
              style={{ filter: "grayscale(100%)", opacity: "0.95" }}
              src={image}
              alt="We will fight for you"
            />
          </div>
        </Transition>
      ))}
    </>
  )
}

export default Carousel
